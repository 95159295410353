






import { Component, Vue } from 'vue-property-decorator';
import Settings from "@/packages/settings/Settings";
import {Auth} from "@/packages/auth/Auth";
import AskQuestion from "@/components/AskQuestion.vue";
@Component({
  components: {AskQuestion}
})
export default class QuestionAdd extends Vue {

}
